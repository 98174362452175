<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="h-100">
          <div v-if="!selectedPage" class="headings">
            <h1>Welcome to the Fluke Experience</h1>
            <h3>Continue your virtual journey here...</h3>
            <div class="button-cnt mt-5">
              <h5>New to Fluke?</h5>
              <!-- <button @click="selectedPage = 'register'">Register Here</button> -->
              <v-btn @click="selectedPage = 'register'" elevation="5"
                >Register Here</v-btn
              >

              <h5 class="mt-5">Already registered?</h5>
              <!-- <button @click="selectedPage = 'login'">Login Here</button> -->
              <v-btn @click="selectedPage = 'login'" elevation="5"
                >Login Here</v-btn
              >
            </div>
          </div>
          <div v-if="selectedPage" class="register">
            <Register
              @onLogin="selectedPage = 'login'"
              @onBack="selectedPage = null"
              v-if="selectedPage == 'register'"
            ></Register>
            <Login
              @onBack="selectedPage = null"
              v-if="selectedPage == 'login'"
            ></Login>
          </div>
        </div>
        <div
          data-dismiss="modal"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="close1"
          id="registerClose"
          @click="onClose()"
        >
          <v-icon color="black" large>mdi-close</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Register from "./Register.vue";
import Login from "./Login.vue";

export default {
  data: () => {
    return {
      selectedPage: null,
    };
  },

  components: {
    Register,
    Login,
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.selectedPage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  height: auto;
  background-color: transparent;

  // max-width: 60vw !important;
  // min-height: 70vh !important;
}
.modal-content {
  background-image: url("../assets/images/register_bg.png");
  background-color: #fbb913;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: auto;
  position: relative;
  padding: 30px;
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.headings {
  width: 60%;
  text-align: center;
  margin-top: 15%;
  float: right;
  padding-bottom: 12vh;
  h1 {
    font-family: "Gothic A1";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;

    color: #000000;
  }
  h3 {
    font-family: "Gothic A1";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
  }
  .button-cnt {
    flex-direction: column;
    h5 {
      font-family: Gothic A1;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #000000;
    }
    button {
      background: linear-gradient(
        106.09deg,
        rgba(253, 253, 253, 0.85) 9.1%,
        rgba(255, 255, 255, 0.75) 96.17%
      );
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(40px);
      border-radius: 8px;
      padding: 8px 25px;

      font-family: Gothic A1;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      color: #000000;
    }
  }
}

.register {
  margin-top: 7%;
  padding-bottom: 12vh;
}
.close1 {
  width: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
}
@media (max-width: 767.98px) {
  // @import 'responsive/sm';
  .modal-dialog {
    // max-width: 90vw !important;
  }
}

@media (max-width: 575.98px) {
  .headings {
    float: none;
    width: 100%;
    margin-top: 10vh !important;
  }
  .modal-content {
    padding: 15px;
  }
  .register {
    margin-top: 10vh;
  }
}
</style>
