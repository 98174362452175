<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <!-- <div class="close1"> -->
        <img
          data-dismiss="modal"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="close1 mt-2"
          @click="onClose()"
          src="../assets/images/cross.png"
        />
        <!-- </div> -->
        <div class="product-text mt-3 ml-2">
          <iframe v-bind:src="meetingUrl"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";

export default {
  data: () => {
    return {
      meetingUrl: "",
    };
  },
  mounted() {
    if (store.state.user) {
      this.meetingUrl = `https://calendly.com/flukeindia/businessmeeting?name=${store.state.user.name}&email=${store.state.user.email}&a1=${store.state.user.mobile_no}`;
    } else {
      this.meetingUrl = `https://calendly.com/flukeindia/businessmeeting`;
    }
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;

  height: auto;
  min-width: 75vw !important;
  width: auto;
  // margin-top: 10vh;
  padding: 0px;
}
.modal-content {
  height: auto;
  background-color: transparent;
  padding: 0px;
  border-width: 0px !important;
  position: relative;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 80vh !important;
  background-color: #fff;
}
.close1 {
  width: 30px;
  position: absolute;
  top: 50px;
  right: 40px;
}

@media (max-width: 767.98px) {
  // @import 'responsive/sm';
  .modal-dialog {
    // margin-top: 10vh;
    max-width: 95vw !important;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 100vw !important;
    width: 100vw !important;
    margin: auto !important;
    // margin-top: 30vh;
  }
}
</style>
