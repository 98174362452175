









import Vue from "vue";
import { imageBaseURL } from "../helper/config";

export default Vue.extend({
  name: "Videos",
  data: () => {
    return {
      imageBaseURL: imageBaseURL,
    };
  },
  props: {

    videos: [],
  },
  methods: {
    onVideo(video:any) {
      this.$emit("onSelect", video);
    },
  },
});
