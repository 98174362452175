<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <img
          data-dismiss="modal"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="close1"
          @click="onClose()"
          src="../assets/images/cross.png"
        />
        <div class="product-text">
          <img class="bg-img" src="../assets/images/guide-bg.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.isVideo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;
  height: auto;
  min-width: 35vw !important;
  padding: 0px;
}
.modal-content {
  height: auto;
  background-color: #000000;
  padding: 0px;
  border-width: 0px !important;
  position: relative;
}

.modal-content::-webkit-scrollbar {
  display: none;
}
.close1 {
  width: 30px;
  position: absolute;
  top: 50px;
  right: 40px;
}

.bg-img {
  width: 100%;
  height: 100%;
}




</style>
