<template>
  <div id="app" data-app>
    <div class="logo-holder">
      <div class="logo-img">
        <img src="./assets/images/fluke.png" />
      </div>
    </div>
    <div class="logo-name-holder">
      Powered by 
      <a class="logo-name text-white" target="_blank" href="https://www.exhibit-360.com/?ref=fluke">Exhibit 360</a>
    </div>


    <div class="buttons-holder" v-if="!showBack">
      <button class="yellow-button" @click="goToPano('everyday-solutions-main')">Everyday Solutions</button>
      <button class="yellow-button" @click="goToPano('ev-solutions-main')">Electric Vehicle Solutions</button>
      <button class="yellow-button" @click="goToPano('new-product-solutions-main')">New Product Solutions</button>
    </div>
    <div class="buttons-holder" v-else>
      <button class="yellow-button" @click="goToPano(getBackPano)">Back</button>
    </div>

    <div class="menu-cnt column">
      <div class="column">
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="image" @click="goFullScreen()">
              <img class="icon" src="./assets/images/Vector.png" />
            </div>
          </template>
          <span>Full Screen</span>
        </v-tooltip> -->

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="image" @click="onContact()">
              <img class="icon" src="./assets/images/call.png" />
            </div>
          </template>
          <span>Contact</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="image" @click="onGuide()">
              <img class="icon" src="./assets/images/mouse.png" />
            </div>
          </template>
          <span>Guide</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="image" @click="onShare">
              <img class="icon" src="./assets/images/share.png" />
            </div>
          </template>
          <span>Share</span>
        </v-tooltip>
      </div>
    </div>
    <ProductModal @onVideo="onVideo" @onSchedule="onSchedule()" @onClose="onProductClose()"
      @onCallback="onRequestCallback" v-bind:products="products" ref="productModal"></ProductModal>
    <VideoModel v-if="token" @onVideo="onVideo" v-bind:videos="selectedVideo" ref="videoModal"></VideoModel>
    <PlayerModel v-if="videoFile && token" v-bind:file="videoFile" @onClose="closePlayerModel()" ref="playerModal">
    </PlayerModel>
    <RegisterModel v-if="registerModal" ref="registerModal"></RegisterModel>
    <ShareModel v-if="sharedModal" ref="sharedModal"></ShareModel>
    <ContactModel v-bind:screen="contactScreen" v-bind:product="selectedProduct" v-if="contactModal" ref="contactModal">
    </ContactModel>
    <GuideModel v-if="guideModel" ref="guideModel"></GuideModel>

    <ScheduleModel v-if="scheduleModel" @onClose="closeScheduleModel()" ref="scheduleModel"></ScheduleModel>
    <MobileBlocker/>
  </div>
</template>

<script >
import { Component, Vue } from "vue-property-decorator";
import ProductModal from "./components/ProductModal.vue";
import RegisterModel from "./components/RegisterModel.vue";
import VideoModel from "./components/VideoModel.vue";
import PlayerModel from "./components/PlayerModel.vue";
import ShareModel from "./components/ShareModel.vue";
import ContactModel from "./components/ContactModel.vue";
import GuideModel from "./components/GuideModel.vue";
import ScheduleModel from "./components/ScheduleModel.vue";
import MobileBlocker from "./components/MobileBlocker.vue";

import { matchVideoId, matchProductId } from "./helper/executeIds";
import { getProductByGtm, getVideos, getProductByID } from "./helper/controller";
import {
  sendSkinEvent,
  sendProductEvent,
  sendProductItemEvent,
} from "./helper/gtmTags";
import store from "./store";

@Component({
  components: {
    ProductModal,
    RegisterModel,
    VideoModel,
    PlayerModel,
    ShareModel,
    ContactModel,
    GuideModel,
    ScheduleModel,
    MobileBlocker
  },
  name: "App",

  mounted() {
    window.executeTag = this.executeTag.bind(this);
    window.enterPano = this.enterPano.bind(this);
    window.exitPano = this.exitPano.bind(this);
    this.isMuted = localStorage.getItem("isMuted") == "true" ? true : false;
    store.dispatch("setUserDataFromLocal");
    store.dispatch("setChatName");
  },
  computed: {
    token() {
      return store.state.token;
    },
    showBack() {
      switch(this.currentPano){
        case 'ev-solutions-1':
          return true;
        case 'ev-solutions-2':
          return true;
        case 'ev-solutions-3':  
          return true;
        case 'nps-ldm':  
          return true;
        case 'nps-dm':  
          return true;
        case 'nps-pqa':  
          return true;
        case 'nps-virt':  
          return true;
        default:
          return false;
      }
    },
    getBackPano() {
      switch(this.currentPano){
        case 'ev-solutions-1': return 'ev-solutions-main';
        case 'ev-solutions-2': return 'ev-solutions-main';
        case 'ev-solutions-3': return 'ev-solutions-main';
        case 'nps-ldm': return 'new-product-solutions-main';
        case 'nps-dm': return 'new-product-solutions-main';
        case 'nps-pqa': return 'new-product-solutions-main';
        case 'nps-virt': return 'new-product-solutions-main';
        default: return 'everyday-solutions-main';
      }
    }
  },
  data: () => {
    return {
      currentPano: null,
      selectedProduct: Object,
      products: [],
      selectedVideo: [],
      videoFile: "",
      sharedModal: false,
      isMuted: false,
      contactModal: false,
      guideModel: false,
      scheduleModel: false,
      registerModal: false,
      productModal: false,
      contactScreen: "contact",
    };
  },
  methods: {
    enterPano(pano) {
      this.currentPano = pano;
    },
    exitPano() {
      this.currentPano = null;
    },
    executeTag(id) {
      if (this.token) {
        if (matchVideoId(id)) {
          getVideos(id).then((res) => {
            if (res) {
              const selectedVideo = res.items.map((item) => item.items_id);
              if (selectedVideo.length > 1) {
                this.selectedVideo = res.items.map((item) => item.items_id);
                this.onVideo(selectedVideo[0]);

                // Uncoment if want to show video list if product has multiple tv videos
                // this.$refs.videoModal.showModal();
              } else {
                this.onVideo(selectedVideo[0]);
              }
            }
          });
        } else if (matchProductId(id)) {
          if (id == '250e6a4b-aecf-477a-a5b2-570a287b7a60') {
            id = 'cecbeae3-be36-4d72-841b-0e29c2434aa6';
          }
          getProductByGtm(id).then((res) => {
            if (res) {
              this.products = res;
              this.productModal = true;
              setTimeout(() => {
                this.$refs.productModal.showModal();
              }, 300);

            }
          });
        } else if (id == "f5d07984-adcf-4a64-8b97-a167229c4923") {
          this.onShare();
        } else if (id == "da55e005-92ff-4d4c-bf32-7ebdfc3fce04") {
          sendProductEvent(
            "Social Wall",
            "Instagram",
            "https://www.instagram.com/flukeindia/"
          );
          window.open("https://www.instagram.com/flukeindia/", "_blank");
        } else if (id == "5287a156-529c-4cec-a88c-8e970287c6ff") {
          sendProductEvent(
            "Social Wall",
            "Youtube",
            "https://www.youtube.com/c/FlukeIndia1/"
          );
          window.open("https://www.youtube.com/c/FlukeIndia1/", "_blank");
        } else if (id == "d881be05-549a-400a-9fb6-c4a56d401120") {
          sendProductEvent(
            "Social Wall",
            "Linkedin",
            "https://www.linkedin.com/company/fluke-corporation/"
          );
          window.open(
            "https://www.linkedin.com/company/fluke-corporation/",
            "_blank"
          );
        } else if (id == "c7a0988b-8117-4bae-b4b2-473faf74040e") {
          sendProductEvent(
            "Social Wall",
            "Facebook",
            "https://www.facebook.com/FlukeIndia"
          );
          window.open("https://www.facebook.com/FlukeIndia", "_blank");
        }
      } else {
        this.registerModal = true;
        setTimeout(() => {
          this.$refs.registerModal.showModal();
        }, 300);
      }
    },

    onShare() {
      this.sharedModal = true;
      setTimeout(() => {
        this.$refs.sharedModal.showModal();
      }, 200);
      sendSkinEvent("Social Share");
    },
    onVideo(file) {
      this.videoFile = file.file;
      setTimeout(() => {
        this.$refs.playerModal.showModal();
      }, 300);
      getProductByID(file.product_id).then((res) => {
        if (res) {
          sendProductItemEvent(
            res.gtm.zone_name,
            res.gtm.station_name,
            res.gtm.product_name,
            "Videos Viewed",
            file.name
          );
        }
      });
    },

    goFullScreen() {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
      sendSkinEvent("Full Screen");
    },

    onContact() {
      this.contactScreen = "contact";
      this.selectedProduct = {
        id: 9
      }
      this.contactModal = true;
      setTimeout(() => {
        this.$refs.contactModal.showModal();
      }, 300);
      sendSkinEvent("Contact us");
    },
    onGuide() {
      this.guideModel = true;
      setTimeout(() => {
        this.$refs.guideModel.showModal();
      }, 300);
      sendSkinEvent("Quick Guide");
    },

    onSchedule() {
      this.scheduleModel = true;
      setTimeout(() => {
        this.$refs.scheduleModel.showModal();
      }, 100);
    },
    onRequestCallback(args) {
      this.selectedProduct = args;
      this.contactScreen = "callback";
      this.contactModal = true;
      setTimeout(() => {
        this.$refs.contactModal.showModal();
      }, 300);
    },
    closePlayerModel() {
      this.videoFile = null;
    },
    closeScheduleModel() {
      this.scheduleModel = false;
    },
    onProductClose() {
      this.productModal = false;
    },
    goToPano(target) {
      window.setMediaByName(target);
    }
  },
  destroyed() {
    // window.history.pushState(null, null, "/");
  },
})
export default class App extends Vue { }
</script>

<style lang="scss">
@import "./assets/scss/main.scss";

.column {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.row {
  display: flex;
  flex-direction: row;
  margin-right: 0px !important;
}

@media (max-width: 992px) {
  .icon {
    height: 18px !important;
    width: 18px !important;
  }
  .image {
    height: 35px !important;
    width: 35px !important;
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.logo-img > img {
  // position: absolute;
  z-index: 999;
  left: 30px;
  top: 30px;
  height: 35px;
}

// Media Queries
@media (max-width: 992px) {
  .logo-img > img {
    height: 25px;
  }
}

.logo-name-holder {
  position: absolute;
  z-index: 9999999;
  left: 30px;
  bottom: 20px;
  color: #fff;
  font-size: 0.725rem;
}

.logo-name:hover {
  color: #fff;
}

.image {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  background-color: grey;
  align-items: center;
  justify-content: center;
  margin: 4px 0px;
  border-color: rgba(255, 208, 50, 0.5);
  border: 1px solid;
  background: linear-gradient(140.19deg,
      rgba(37, 37, 37, 0.7) 16.47%,
      rgba(37, 37, 37, 0.5) 84.48%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  cursor: pointer;
}

.menu-cnt {
  z-index: 999;
  position: absolute;
  right: 15px;
  top: 20px;
}

.back-button{
    color: #000000;
    background-color: #d2cfcf;
    padding: 5px 8px;
    display: flex;
    align-items: baseline;
    span{
      font-size: 16px;
      padding-right: 8px;
      line-height: normal;
    }

    img {
      width: 12px;
      margin-right: 8px;
    }
}

.logo-holder {
  position: absolute;
  z-index: 99999999;
  top: 0px;
  left: 0px;
  margin: 30px;
}

.buttons-holder{
  position: absolute;
  z-index: 99999;
  bottom: 0px;
  width: 100%;
  margin: auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  // button{
  //   background: #E6E6E6;
  //   margin-bottom: 20px;
  //   color: #000000;
  //   text-align: center;
  //   font-size: small;
  //   padding: 5px 20px;
  // }
}

.modal{
  background: rgba(0, 0, 0, 0.5)!important;
  z-index: 99999999999!important;
}

@media (max-width: 992px) {
  .yellow-button {
    display: inline-flex;
    padding: 6px 10px !important;
    justify-content: center;
    align-items: center;
    gap: 9px;
    font-size: 10px !important;
  }
}

.yellow-button {
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  font-size: 14px;
  color: #FFFDFD;
  border-radius: 10px;
  border: 2.5px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(106deg, rgba(134, 134, 134, 0.8) 9.1%, rgba(97, 97, 97, 0.9) 96.17%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.logo-name{
  text-decoration: underline !important;
}
</style>