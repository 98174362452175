<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <video
          className="video-container video-container-overlay"
          autoPlay="true"
          v-bind:muted="isMuted"
          controls
          data-reactid=".0.1.0.0"
          v-if="isVideo && file"
        >
          <source
            type="video/mp4"
            data-reactid=".0.1.0.0.0"
            v-bind:src="imageBaseURL + file"
          />
        </video>
        <img
          data-dismiss="modal"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="close1"
          @click="onClose()"
          src="../assets/images/cross.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { imageBaseURL } from "../helper/config";
import store from "../store";

export default {
  data: () => {
    return {
      imageBaseURL: imageBaseURL,
      isVideo: true,
    };
  },
  props: ["file"],

  components: {},
  computed: {
    isMuted() {
      return store.state.isMuted;
    },
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.isVideo = false;
      this.$emit("onClose");

    },
  },
  watch: {
    file: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.isVideo = false;
        setTimeout(() => {
          this.isVideo = true;
        }, 100);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;
 
  height: auto;
  max-width: 70vw;
}
.modal-content {
   border: double 2.5px transparent;
  border-radius: 10px;
  background-image: linear-gradient(106.09deg, #5f5f5f, #202020),
    linear-gradient(
      91.25deg,
      rgba(255, 255, 255, 0.5) 31.9%,
      rgba(255, 230, 146, 0.5) 80.07%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  height: auto;
  padding: 0px;
  position: relative;
}
.modal-content::-webkit-scrollbar {
  display: none;
}


.close1 {

  width: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
}
@media (max-width: 767.98px) {
  // @import 'responsive/sm';
  .modal-dialog {
    // margin-top: 10vh;
    // max-width: 85vw !important;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 100vw;
  }
}
</style>
