export const videoList = [
  "658aa6a9-92f0-4c4a-8fc6-1dfbfafef39a",
  "e6b6597e-5d08-4ac8-822b-550a4dba102c",
  "386663df-b52d-4445-b892-4c83eb11a3e7",
  "ce86a646-949d-443f-a6cc-0ee2f57d4f05",
  "360a5b5c-a1da-4bb9-af3c-2235c2f1bdbd",
  "df214430-3481-42f1-9f1c-57114757863f",
  "8875ac19-1638-4626-bf0a-70b0ed0c61f4",
  "0fd213c8-8ac4-44e5-8111-88cb815fc405",
  "6c65ca4f-60fc-41f1-a79c-e5125552daf2",
  "d11cd90d-34ff-43f9-8139-4939a670f41b"

];

export const productList = [
  "250e6a4b-aecf-477a-a5b2-570a287b7a60",
  "cecbeae3-be36-4d72-841b-0e29c2434aa6",

  "8410ab8d-2247-4bf6-af52-009a0d64fad8",
  "48a826c2-ffe2-41df-86dc-e8c75ff26215", 
  "9ed7a563-1518-40fc-956d-9336c631667a",
  "d6d037b3-f60b-47ca-a3e8-119a9fdc7e8d",
  "9bfb94f5-e44d-4211-915a-9ec55f715558",
  "8568185a-37a9-4a46-a5d7-5a0c0ab281de",
  "0c1835bd-ca67-4482-b6a2-dcbf04597a43",
  "26346436-9c1d-40d8-a345-cd445a40d377",
  "1baf2cc8-bbbf-44db-9aff-e341d0d2cff2",
  "4ade3551-314e-474a-b246-cc094d183043",
  "baaa01fe-9501-46dd-9655-d24e87a24dbd",
  "2b153a49-4c50-41e5-8b0d-1cea62754f26",
  "31d8b536-c141-47f4-84f2-b4493ed23ba6",
  "b2092777-088f-471a-98f5-d96873f92814"
];  

export const matchVideoId = (id: string) => {
  const index = videoList.findIndex((l) => l === id);
 return index > -1 ? true : false;
};
export const matchProductId = (id: string) => {
  var index = productList.findIndex((l) => l === id);
 return index > -1 ? true : false;
};
