<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="close1">
          <button v-if="products.length > 1 && isDetails" @click="onBack()">
            <img  src="../assets/images/Left.png" />
            <span>Back</span>
          </button>
        </div>
        <img class="close-button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" @click="onClose()"
          src="../assets/images/cross.png" />

        <div v-if="!isDetails" class="h-100">
          <h1 class="gtm-title pt-5">{{ products[0].gtm.station_name }}</h1>
          <div class="products-list-cnt">
            <div class="list-item-cnt" v-for="p in products" :key="p.id">
              <div class="list-item" @click="selectProduct(p)">
                <div class="list-image">
                  <img v-bind:src="imageBaseURL + p.image" />
                </div>
                <div class="pname-cnt">
                  <h2>{{ p.name }}</h2>
                </div>
              </div>
              <div class="action-cnt">
                <button @click="selectProduct(p)">Explore</button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isDetails" class="h-100">
          <div class="tab-cnt d-flex">
            <div @click="changeTab(0)" :class="selectedTabIndex == 0 ? 'tab-item selected' : 'tab-item'">
              Information
            </div>
            <div @click="changeTab(1)" :class="selectedTabIndex == 1 ? 'tab-item selected' : 'tab-item'">
              Videos
            </div>
            <div @click="changeTab(2)" :class="selectedTabIndex == 2 ? 'tab-item selected' : 'tab-item'">
              Literature
            </div>
            <div @click="changeTab(3)" v-if="product && (product.id == 2 || product.id == 22)"
              :class="selectedTabIndex == 3 ? 'tab-item selected' : 'tab-item'">
              Testimonials
            </div>
          </div>
          <div class="product-card row mt-2 mt-md-5 w-100">
            <div v-if="product && product.image && !selectedFile && !selectedVideo" class="image-card col-lg-7">
              <img @click="slideChange('left')" v-if="sliderImageIndex !== 0" class="control left" src="../assets/images/Left.png" />
              <img @click="slideChange('right')" v-if="(product.slider_image.length > 0) && ((product.slider_image.length - 1) !== sliderImageIndex)" class="control right" src="../assets/images/Right.png" />
              <img v-if="product.slider_image && product.slider_image.length > 0" v-bind:src="imageBaseURL +
                product.slider_image[sliderImageIndex].directus_files_id
                " />
              <img v-if="!product.slider_image || !product.slider_image.length"
                v-bind:src="imageBaseURL + product.image" />
            </div>
            <div v-if="product && product.url && !selectedFile && !selectedVideo" class="image-card col-lg-7">
              <iframe v-bind:src="product.url"></iframe>
            </div>
            <div v-if="selectedFile" class="image-card col-lg-7">
              <iframe v-bind:src="selectedFile"></iframe>
            </div>

            <div v-if="selectedVideo" class="image-card col-lg-7">
              <video class="video-container" v-bind:muted="isMuted" autoPlay="true" controls>
                <source type="video/mp4" data-reactid=".0.1.0.0.0" v-bind:src="selectedVideo" />
              </video>
            </div>

            <div class="product-details col-lg-5">
              <div class="product-content-cnt">
                <Information v-bind:product="product" v-if="selectedTabIndex == 0"></Information>
                <Videos v-bind:videos="getFilterItems('videos')" @onSelect="onVideo" v-if="selectedTabIndex == 1">
                </Videos>
                <Literature @onSelect="onPdfFile" @onDownload="onFileDownload"
                  v-bind:literature="getFilterItems('literature')" v-if="selectedTabIndex == 2"></Literature>
                <Testimonials @onSelect="onPdfFile" @onDownload="onFileDownload"
                  v-bind:testimonials="getFilterItems('testimonials')" v-if="selectedTabIndex == 3"></Testimonials>
              </div>

              <div class="contact-cnt mt-3">
                <div class="d-flex w-100 justify-content-center">
                  <v-btn class="d-flex mr-2 contact-btn" @click="requestCallback()" elevation="5">
                    Request a Callback
                  </v-btn>
                  <v-btn class="d-flex ml-2 contact-btn" @click="onSchedule()" elevation="5">
                    Schedule a meeting
                  </v-btn>
                </div>
                <v-btn v-if="product.redirect_link" @click="onKnowMore(product.redirect_link)" class="d-flex contact-btn mt-3" elevation="5">
                  Learn more
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Information from "./Information.vue";
import Videos from "./Videos.vue";
import Literature from "./Literature.vue";
import Testimonials from "./Testimonials.vue";
import { imageBaseURL, fileReadURL } from "../helper/config";
import store from "../store";
import { sendProductItemEvent, sendProductEvent } from "../helper/gtmTags";

export default {
  data: () => {
    return {
      selectedTabIndex: 0,
      imageBaseURL: imageBaseURL,
      selectedFile: "",
      selectedVideo: "",
      isDetails: true,
      sliderImageIndex: 0,
      product: {},
    };
  },
  props: ["products"],

  computed: {
    isMuted() {
      return store.state.isMuted;
    },
  },

  components: {
    Information,
    Videos,
    Literature,
    Testimonials,
  },
  destroyed() {
    this.selectedFile = null;
    this.selectedVideo = null;
    this.isDetails = false;
  },

  methods: {
    showModal() {
      if (this.products.length > 1) {
        this.isDetails = false;
      } else {
        this.product = this.products[0];
        this.isDetails = true;
        sendProductEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name
        );
        if (this.product.url) {
          sendProductItemEvent(
            this.product.gtm.zone_name,
            this.product.gtm.station_name,
            this.product.gtm.product_name,
            "3D Model Viewed",
            "3D Model"
          );
        }
      }
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },

    slideChange(d) {
      if (d == "left") {
        if (this.sliderImageIndex > 0) {
          this.sliderImageIndex--;
        } else {
          this.sliderImageIndex = this.product.slider_image.length - 1;
        }
      } else {
        if (this.sliderImageIndex < this.product.slider_image.length - 1) {
          this.sliderImageIndex++;
        } else {
          this.sliderImageIndex = 0;
        }
      }
    },

    selectProduct(product) {
      this.product = product;
      this.isDetails = true;
    },
    onBack() {
      this.isDetails = false;
      this.sliderImageIndex = 0;
      this.selectedTabIndex = 0;
      this.selectedFile = null;
      this.selectedVideo = null;
    },
    changeTab(index) {
      this.sliderImageIndex = 0;
      this.selectedTabIndex = index;
      this.selectedFile = null;
      this.selectedVideo = null;
      switch (index) {
        case 1:
          this.onVideo(this.getFilterItems("videos")[0]);
          break;
        case 2:
          this.onPdfFile(this.getFilterItems("literature")[0]);
          break;
        case 3:
          this.onPdfFile(this.getFilterItems("testimonials")[0]);
          break;

        default:
          break;
      }
    },
    getFilterItems(key) {
      return this.product.items.filter((item) => item.category == key);
    },
    onClose() {
      this.sliderImageIndex = 0;
      this.selectedTabIndex = 0;
      this.selectedVideo = null;
      this.selectedFile = null;
      this.product = null;
      this.$emit("onClose");
    },
    onVideo(file) {
      this.selectedVideo = null;
      setTimeout(() => {
        this.selectedVideo = imageBaseURL + file.file;
      }, 100);
      sendProductItemEvent(
        this.product.gtm.zone_name,
        this.product.gtm.station_name,
        this.product.gtm.product_name,
        "Videos Viewed",
        file.name
      );
    },
    onPdfFile(file) {
      this.selectedFile = fileReadURL + imageBaseURL + file.file;
      if (file.type == "guide") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Guide Viewed ( PDF Format )",
          file.name
        );
      } else if (file.type == "app-note") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Appnote Viewed ( PDF Format )",
          file.name
        );
      } else if (file.type == "case-study") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Case Studies Viewed ( PDF Format )",
          file.name
        );
      } else if (file.type == "customer-testimonial") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Customet Testimonials Viewed ( PDF Format )",
          file.name
        );
      } else if (
        file.type == "datasheet" ||
        file.type == "detailed-specification"
      ) {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Brochure Viewed ( PDF Format )",
          file.name
        );
      }
    },

    onFileDownload(file) {
      if (file.type == "guide") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Guide Downloaded ( PDF Format )",
          file.name
        );
      } else if (file.type == "app-note") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Appnote Downloaded ( PDF Format )",
          file.name
        );
      } else if (file.type == "case-study") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Case Studies Downloaded ( PDF Format )",
          file.name
        );
      } else if (file.type == "customer-testimonial") {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Customer Testimonials Downloaded (PDF Format )",
          file.name
        );
      } else if (
        file.type == "datasheet" ||
        file.type == "detailed-specification"
      ) {
        sendProductItemEvent(
          this.product.gtm.zone_name,
          this.product.gtm.station_name,
          this.product.gtm.product_name,
          "Brochure Downloaded ( PDF Format )",
          file.name
        );
      }
    },
    chatWith() {
      window.Tawk_API.maximize();
      sendProductItemEvent(
        this.product.gtm.zone_name,
        this.product.gtm.station_name,
        this.product.gtm.product_name,
        "Chat with Expert",
        null
      );
    },
    onKnowMore(link) {
      window.open(link, "_blank");
      // if (this.product.id == 2) {
      //   window.open(
      //     "https://www.fluke.com/en-in/product/industrial-imaging/sonic-industrial-imager-ii900",
      //     "_blank"
      //   );
      // } else if (this.product.id == 3) {
      //   window.open(
      //     "https://www.fluke.com/en-in/product/electrical-testing/clamp-meters/fluke-362",
      //     "_blank"
      //   );
      // } else if (this.product.id == 6) {
      //   window.open(
      //     "https://www.fluke.com/en-in/product/electrical-testing/digital-multimeters/fluke-115",
      //     "_blank"
      //   );
      // } else if (this.product.id == 7) {
      //   window.open(
      //     "https://www.fluke.com/en-in/product/building-infrastructure/indoor-air-quality-testing/fluke-971",
      //     "_blank"
      //   );
      // } else if (this.product.id == 5) {
      //   window.open(
      //     "https://www.fluke.com/en-in/product/temperature-measurement/ir-thermometers/fluke-62-max-plus",
      //     "_blank"
      //   );
      // }
      sendProductItemEvent(
        this.product.gtm.zone_name,
        this.product.gtm.station_name,
        this.product.gtm.product_name,
        "Know More"
      );
    },
    onSchedule() {
      this.$emit("onSchedule", this.product);
      sendProductItemEvent(
        this.product.gtm.zone_name,
        this.product.gtm.station_name,
        this.product.gtm.product_name,
        "Schedule a Meeting",
        null
      );
    },

    requestCallback() {
      this.$emit("onCallback", this.product);
      sendProductItemEvent(
        this.product.gtm.zone_name,
        this.product.gtm.station_name,
        this.product.gtm.product_name,
        "Request a Callback",
        null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;
  height: auto;
  max-width: 80vw;
}

.modal-content {
  background-image: url("../assets/images/popup_bg1.jpg");
  background-color: #000000;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: auto;
  padding: 30px;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.tab-cnt {
  width: calc(35.825rem + 0.01vw);
  height: 38px;
  margin: auto;
  border: double 2.5px transparent;
  border-radius: 10px;
  background-image: linear-gradient(106.09deg, #5f5f5f, #202020),
    linear-gradient(91.25deg,
      rgba(255, 255, 255, 0.5) 31.9%,
      rgba(255, 230, 146, 0.5) 80.07%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  .tab-item {
    color: #ffffff;
    text-decoration: none;
    width: calc(12.825rem + 0.01vw);
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2.5px;
    cursor: pointer;
    font-size: calc(0.825rem + 0.01vw);
  }

  div.selected {
    border: double 2.5px transparent;
    border-radius: 10px;
    background-image: linear-gradient(106.09deg, #5f5f5f, #202020),
      linear-gradient(91.25deg,
        rgba(255, 255, 255, 0.5) 31.9%,
        rgba(255, 230, 146, 0.5) 80.07%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}

.product-card {
  height: 80%;
  backdrop-filter: blur(40px);
  border: solid 2.5px #635e4d;
  margin: 0px;
  border-radius: 20px;
  padding: 10px;
}

.image-card {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.23));
  width: 100%;
  max-height: 60vh;
  padding: 0px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  iframe {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border-radius: 15px;
  }

  .control {
    position: absolute;
    top: 50%;
    height: 26px!important;
    width: 26px;
    cursor: pointer;
  }

  .left {
    left: 20px;
  }

  .right {
    right: 20px;
  }
}

.product-content-cnt::-webkit-scrollbar {
  display: none;
}

.product-content-cnt {
  height: 35vh;
  overflow-x: scroll;
}

.product-details {
  .contact-cnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    .contact-btn {
      align-items: center;
      cursor: pointer;
      color: #ffffff;
      font-size: 14px;
      text-transform: none;

      padding: 10px;
      height: 32px;
      background: linear-gradient(105.47deg,
          rgba(255, 255, 255, 0.5) 11.6%,
          rgba(255, 255, 255, 0.15) 96.15%);
      font-family: Roboto;
      font-style: normal;

      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        font-size: calc(0.725rem + 0.01vw);
        line-height: 14px;
        margin: 0px 8px;
        color: #ffffff;
        mix-blend-mode: normal;
      }
    }
  }
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 999999;
}

@media (max-width: 992px) {
  .close1 {
    position: initial !important;
    margin-bottom: 10px !important;
  }
  .close-button {
    position: initial !important;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px !important;
  }
}

.close1 {
  // position: absolute;
  // width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    width: 30px;
    margin-left: auto;
    cursor: pointer;
    z-index: 999999;
  }

  button {
    color: #000000;
    background-color: #d2cfcf;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    span{
      padding-right: 8px;
    }

    img {
      width: 20px;
      margin-right: 8px;
    }
  }
}

.video-container {
  width: 100%;
  height: 100%;
}

.gtm-title {
  color: #ffffff;
  text-align: center;
  margin-top: -34px;
  font-size: calc(0.8rem + 1.5vw);
}

.products-list-cnt {
  display: flex;
  gap: 30px;
  margin-top: 40px;
  justify-content: center;

  .list-item-cnt {
    width: 40%;

    .action-cnt {
      display: flex;
      margin-top: 20px;

      button {
        border: 2.5px solid #ffe692;
        padding: 10px 20px;
        border-radius: 10px;
        border: 2.5px solid #635e4d;
        color: #ffffff;
        margin: auto;
      }
    }
  }

  .list-item {
    border-radius: 20px;
    border: 2.5px solid #635e4d;
    background-color: rgba(104, 104, 104, 0.62);
    padding: 20px;
    cursor: pointer;

    .list-image {
      border-bottom: #ebebeb 1px solid;
      padding-bottom: 26px;
      display: flex;
      height: 35vh;

      img {
        border-radius: 20px;
        max-width: 100%;
        max-height: 260px;
        margin: auto;
        max-height: 80%;
      }
    }

    .pname-cnt {
      min-height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;

      h2 {
        color: #ffffff;
        text-align: center;
        font-size: calc(0.5rem + 0.9vw);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .modal-dialog {}

  .modal-content {
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
  }

  .product-card {
    height: auto !important;
  }

  .image-card {
    img {
      height: 60vh !important;
    }
  }

  .product-details {
    margin-top: 15px;
  }

  iframe {
    min-height: 40vh;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .tab-cnt {
    width: calc(25.825rem + 0.01vw);
  }

  .products-list-cnt {
    flex-direction: column;
    align-items: center;
  }

  .products-list-cnt {
    .list-item-cnt {
      width: 80%;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 100vw;
  }

  .modal-content {
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    padding: 15px !important;
  }

  .image-card {
    padding: 0px !important;
    position: relative;
  }

  .tab-cnt {
    width: 100% !important;
  }
}
</style>
