var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app","data-app":""}},[_vm._m(0),_vm._m(1),(!_vm.showBack)?_c('div',{staticClass:"buttons-holder"},[_c('button',{staticClass:"yellow-button",on:{"click":function($event){return _vm.goToPano('everyday-solutions-main')}}},[_vm._v("Everyday Solutions")]),_c('button',{staticClass:"yellow-button",on:{"click":function($event){return _vm.goToPano('ev-solutions-main')}}},[_vm._v("Electric Vehicle Solutions")]),_c('button',{staticClass:"yellow-button",on:{"click":function($event){return _vm.goToPano('new-product-solutions-main')}}},[_vm._v("New Product Solutions")])]):_c('div',{staticClass:"buttons-holder"},[_c('button',{staticClass:"yellow-button",on:{"click":function($event){return _vm.goToPano(_vm.getBackPano)}}},[_vm._v("Back")])]),_c('div',{staticClass:"menu-cnt column"},[_c('div',{staticClass:"column"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"image",on:{"click":function($event){return _vm.onContact()}}},'div',attrs,false),on),[_c('img',{staticClass:"icon",attrs:{"src":require("./assets/images/call.png")}})])]}}])},[_c('span',[_vm._v("Contact")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"image",on:{"click":function($event){return _vm.onGuide()}}},'div',attrs,false),on),[_c('img',{staticClass:"icon",attrs:{"src":require("./assets/images/mouse.png")}})])]}}])},[_c('span',[_vm._v("Guide")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"image",on:{"click":_vm.onShare}},'div',attrs,false),on),[_c('img',{staticClass:"icon",attrs:{"src":require("./assets/images/share.png")}})])]}}])},[_c('span',[_vm._v("Share")])])],1)]),_c('ProductModal',{ref:"productModal",attrs:{"products":_vm.products},on:{"onVideo":_vm.onVideo,"onSchedule":function($event){return _vm.onSchedule()},"onClose":function($event){return _vm.onProductClose()},"onCallback":_vm.onRequestCallback}}),(_vm.token)?_c('VideoModel',{ref:"videoModal",attrs:{"videos":_vm.selectedVideo},on:{"onVideo":_vm.onVideo}}):_vm._e(),(_vm.videoFile && _vm.token)?_c('PlayerModel',{ref:"playerModal",attrs:{"file":_vm.videoFile},on:{"onClose":function($event){return _vm.closePlayerModel()}}}):_vm._e(),(_vm.registerModal)?_c('RegisterModel',{ref:"registerModal"}):_vm._e(),(_vm.sharedModal)?_c('ShareModel',{ref:"sharedModal"}):_vm._e(),(_vm.contactModal)?_c('ContactModel',{ref:"contactModal",attrs:{"screen":_vm.contactScreen,"product":_vm.selectedProduct}}):_vm._e(),(_vm.guideModel)?_c('GuideModel',{ref:"guideModel"}):_vm._e(),(_vm.scheduleModel)?_c('ScheduleModel',{ref:"scheduleModel",on:{"onClose":function($event){return _vm.closeScheduleModel()}}}):_vm._e(),_c('MobileBlocker')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-holder"},[_c('div',{staticClass:"logo-img"},[_c('img',{attrs:{"src":require("./assets/images/fluke.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-name-holder"},[_vm._v(" Powered by "),_c('a',{staticClass:"logo-name text-white",attrs:{"target":"_blank","href":"https://www.exhibit-360.com/?ref=fluke"}},[_vm._v("Exhibit 360")])])}]

export { render, staticRenderFns }