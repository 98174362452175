



























import Vue from "vue";
import { imageBaseURL } from "../helper/config";
// import { saveAs } from "file-saver";
var FileSaver = require('file-saver');

export default Vue.extend({
  name: "Testimonials",
  props: {
    testimonials: [],
  },
  methods: {
    onItem(v: any) {
      // window.open(imageBaseURL + v.file, "_blank");
      this.$emit("onSelect", v);
    },
    onDownload(v: any) {
      FileSaver.saveAs(imageBaseURL + v.file, v.name);
      this.$emit("onDownload", v);

    },
  },
});
