<template>
  <div class="product-text">
    <div class="w-100 d-flex justify-content-end pr-3">
      <v-btn @click="onBack()" class="rounded-10" elevation="5">Back</v-btn>
    </div>
    <div class="col-md-12 mt-2">
      <h1 class="mb-4 ml-3">Welcome back!</h1>
      <v-form ref="form" v-model="isFormValid">
        <div class="col-md-12">
          <v-text-field
            v-model="loginForm.email"
            :rules="emailRules"
            class="w-100"
            placeholder="Email"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-12" v-if="!isResetCode">
          <v-text-field
            v-model="loginForm.code"
            type="number"
            :rules="codeRules"
            class="w-100"
            placeholder="Login code"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-12 mb-2">
          <div class="row my-3">
            <div class="col-4">
              <img
                class="w-100"
                :src="baseURL + '/users/captcha?id=' + seed"
                alt=""
              />
            </div>
            <div class="col-8">
              <v-text-field
                v-model="loginForm.captcha"
                type="number"
                :rules="codeRules"
                class="w-100"
                placeholder="Enter the numbers shown on the left"
                solo
              ></v-text-field>
            </div>

            <a
              class="reset-link mt-3 m-auto"
              href="javascript:void(0)"
              @click="onResetLink()"
              >{{ isResetCode ? "Back to Login?" : "Resend Login code?" }}</a
            >
          </div>
        </div>
      </v-form>
      <div class="col-md-12 d-flex flex-column justify-content-center">
        <div class="pb-2 text-center text-danger">{{ error }}</div>
        <div class="pb-2 text-center text-success">{{ message }}</div>

        <v-btn @click="onSubmit()" class="m-auto rounded-10" elevation="5"
          >Submit</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script >
import Vue from "vue";
import { baseURL } from "../helper/config";
import store from "../store";
import { login, resetCode } from "../helper/controller";
import { sendProductEvent } from "../helper/gtmTags";

export default Vue.extend({
  name: "Login",
  props: {},
  data: () => {
    return {
      loginForm: {
        email: "",
        emailError: "",
        code: "",
        codeError: "",
        captcha: "",
        captchaError: "",
      },
      isResetCode: false,
      seed: Math.round(Math.random() * 100),
      baseURL: baseURL,
      error: "",
      message: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      codeRules: [(v) => !!v || "Login code is required"],
      captchaRules: [(v) => !!v || "Captcha code is required"],
      isFormValid: false,
    };
  },
  methods: {
    onResetLink() {
      this.seed = Math.round(Math.random() * 100);
      this.isResetCode = !this.isResetCode;
      this.resetForm();
    },
    onSubmit() {
      if (this.isFormValid) {
        if (this.isResetCode) {
          let userData = {
            email: this.loginForm.email,
            captcha: this.loginForm.captcha,
          };
          this.submitResetCodeForm(userData);
          // }
        } else {
          let userData = {
            code: this.loginForm.code,
            email: this.loginForm.email,
            captcha: this.loginForm.captcha,
          };
          this.submitLoginForm(userData);
        }
      }
    },
    submitResetCodeForm(user) {
      resetCode(user)
        .then((res) => {
          if (res && res.data && res.isSuccess) {
            this.message = "Login code sent successfully.";
          } else if (res && res.error) {
            this.error = res.error;
          }
        })
        .catch((error) => {
          this.seed = Math.round(Math.random() * 100);
          if (error.response && error.response.data) {
            // Request made and server responded
            this.error = error.response.data.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }

          console.log(error);
        });
    },
    submitLoginForm(user) {
      login(user)
        .then((res) => {
          if (res && res.data && res.isSuccess) {
            store.commit("setUserToken", res.data.token);
            store.commit("setUser", res.data);
            store.dispatch("setChatName");
            sendProductEvent("Login", "Login Success");
            // window.history.pushState(null, null, "/booth");

            document.getElementById("registerClose")?.click();
          } else if (res && res.error) {
            this.error = res.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }
        })
        .catch((error) => {
          this.seed = Math.round(Math.random() * 100);
          if (error.response && error.response.data) {
            // Request made and server responded
            this.error = error.response.data.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }
          console.log(error);
        });
    },

    resetForm() {
      this.loginForm = {
        email: "",
        // emailError: "",
        code: "",
        // codeError: "",
        captcha: "",
        // captchaError: "",
      };
      this.$refs.form.resetValidation();

      this.error = "";
      this.message = "";
    },
    onBack() {
      this.seed = Math.round(Math.random() * 100);
      this.$emit("onBack");
    },
  },
  mounted() {
    // window.history.pushState(null, null, "/login");
  },
  destroyed() {
    // window.history.pushState(null, null, "/");
  },
});
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Gothic A1";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;

  color: #000000;
}

.reset-link {
  font-family: "Gothic A1";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-top: 20px !important;
}
</style>
