<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="close1">
          <img
            data-dismiss="modal"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="onClose()"
            src="../assets/images/cross.png"
          />
        </div>
        <div class="product-text mt-3 ml-2" v-if="screen == 'contact'">
          <h2 class="ml-4">GET IN TOUCH</h2>
          <div class="btn-cnt mt-4">
            <v-btn class="ma-2 facebook-btn">
              <v-icon large dark> mdi-phone </v-icon>
            </v-btn>
            <h4 class="ml-4">
              Toll-free :
              <a target="_blank" href="tel:1800-419-3001">1800-419-3001</a>
            </h4>
          </div>
          <div class="btn-cnt">
            <v-btn class="ma-2 facebook-btn">
              <v-icon large dark> mdi-email </v-icon>
            </v-btn>
            <h4 class="ml-4">
              Email:
              <a target="_blank" href="mailto:info.india@fluke.com"
                >info.india@fluke.com
              </a>
            </h4>
          </div>
          <div class="btn-cnt">
            <v-btn class="ma-2 facebook-btn">
              <v-icon large dark> mdi-web </v-icon>
            </v-btn>
            <h4 class="ml-4">
              Website:
              <a target="_blank" href="https://www.fluke.com/en-in"
                >https://www.fluke.com/en-in
              </a>
            </h4>
          </div>
          <div class="btn-cnt">
            <v-btn @click="onRequestCallback()" class="ma-2 callback">
              Request Callback
            </v-btn>
          </div>
        </div>

        <div class="product-text mt-3 ml-2" v-if="screen == 'callback'">
          <Callback  v-bind:product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Callback from "./Callback.vue";

export default {
  data: () => {
    return {};
  },
  props: ["screen","product"],
  components: {
    Callback,
  },
  methods: {
    onRequestCallback() {
      this.screen = "callback";
    },
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.screen = "contact";
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;

  height: auto;
  min-width: 35vw !important;
  width: auto;
  // margin-top: 20vh;
}
.modal-content {
  background-image: url("../assets/images/register_bg.png");
  background-color: #fbb913;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: auto;
  padding: 30px;
}
.btn-cnt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
.facebook-btn {
  width: calc(3.825rem + 0.01vw) !important;
  height: calc(3.825rem + 0.01vw) !important;
  border-radius: 100%;
  background-color: #ffc20f !important;
  font-size: 34px !important;
}

.callback {
  font-size: 12px !important;
  font-weight: 700;
}

a {
  color: #000000;
}
.close1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    height: 20px;
    margin: 6px;
  }
}
</style>
