<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-content-cnt">
          <div class="close1">
            <img
              data-dismiss="modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="onClose()"
              src="../assets/images/cross.png"
            />
          </div>
          <div class="product-text mt-3 ml-2">
            <h4 class="title">
              Liked our virtual experience? Share this with your colleagues and
              professional network
            </h4>
            <div class="btn-cnt mt-4">
              <v-btn
                class="ma-2 facebook-btn"
                @click="
                  openLink(
                    'https://www.facebook.com/sharer.php?u=https://experiencefluke.com'
                  )
                "
                color="primary"
                dark
              >
                <v-icon dark left> mdi-facebook </v-icon>
                Facebook
              </v-btn>
              <v-btn
                class="ma-2 facebook-btn whatsup"
                @click="
                  openLink('whatsapp://send?text=https://experiencefluke.com')
                "
                color="primary"
                dark
              >
                <v-icon dark left> mdi-whatsapp </v-icon>
                Whatsapp
              </v-btn>
            </div>
            <div class="btn-cnt">
              <v-btn
                class="ma-2 facebook-btn twitter"
                @click="
                  openLink(
                    'https://twitter.com/intent/tweet?url=https://experiencefluke.com'
                  )
                "
                color="primary"
                dark
              >
                <v-icon dark left> mdi-twitter </v-icon>
                Twitter
              </v-btn>

              <v-btn
                class="ma-2 facebook-btn linkedin"
                @click="
                  openLink(
                    'https://www.linkedin.com/shareArticle?url=https://experiencefluke.com'
                  )
                "
                color="primary"
                dark
              >
                <v-icon dark left> mdi-linkedin </v-icon>
                Linkedin
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.isVideo = false;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;
  height: auto;
  min-width: 35vw !important;
}
.modal-content {
  height: auto;
  border: double 2.5px transparent;
  border-radius: 10px;
  background-image: linear-gradient(106.09deg, #5f5f5f, #202020),
    linear-gradient(
      91.25deg,
      rgba(255, 255, 255, 0.5) 31.9%,
      rgba(255, 230, 146, 0.5) 80.07%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;

  padding: 0px;
}

.modal-content-cnt {
  width: 100%;
  height: 100%;
  padding: 30px;
}
.btn-cnt {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.title {
  color: #ffffff;
  padding-bottom: 20px;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
.facebook-btn {
  height: 36px;
  width: calc(10.825rem + 0.01vw);

  padding: 0 16px;
  border-radius: 20px;
  background-color: #4267b2 !important;
}
.twitter {
  background-color: #1da1f2 !important;
}
.linkedin {
  background-color: #0e76a8 !important;
}

.whatsup {
  background-color: #25d366 !important;
}

a {
  color: #ffffff;
}
.close1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    height: 20px;
    margin: 6px;
    color: #fff;
    opacity: 1;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    // max-width: 500px;
    // width: 85vw;
    // margin: 1.75rem auto;
  }
  .facebook-btn {
    width: calc(8.825rem + 0.01vw);
  }
}
</style>
