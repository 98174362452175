<template>
  <div class="modal" ref="baseModal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-content-cnt">
          <div class="close1">
            <img
              data-dismiss="modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="onClose()"
              src="../assets/images/cross.png"
            />
          </div>
          <div class="video-cnt">
            <Videos @onSelect="onVideo" v-bind:videos="videos"></Videos>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Videos from "./Videos.vue";

export default {
  data: () => {
    return {
      selectedPage: null,
    };
  },
  props: {
    videos: [],
  },

  components: {
    Videos,
  },
  methods: {
    showModal() {
      new window.bootstrap.Modal(this.$refs.baseModal).show();
    },
    onClose() {
      this.selectedPage = null;
    },
    onVideo(file) {
      this.$emit("onVideo", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  background-color: transparent;

  height: auto;
}
.modal-content {
  border: double 2.5px transparent;
  border-radius: 10px;
  background-image: linear-gradient(106.09deg, #5f5f5f, #202020),
    linear-gradient(
      91.25deg,
      rgba(255, 255, 255, 0.5) 31.9%,
      rgba(255, 230, 146, 0.5) 80.07%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  height: 50vh;
  // padding: 30px;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
.video-cnt::-webkit-scrollbar {
  display: none;
}

.modal-content-cnt {
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
}

.video-cnt {
  overflow-x: hidden;
  overflow-y: scroll;
  height: inherit;
}

.close1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 30px;
  }
}
@media (max-width: 767.98px) {

  .video-cnt {
    padding-bottom: 40px;
  }
}
</style>
