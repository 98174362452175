<template>
  <div class="product-text">
    <div class="col-md-12 mt-2" v-if="!isSubmited">
      <h1 class="mb-4 ml-3">Request a Callback</h1>
      <v-form ref="form" v-model="isFormValid">
        <h5 class="mb-4 ml-3">
          Please fill out the form and we will call you back
        </h5>
        <div class="col-md-12">
          <v-text-field v-model="loginForm.name" :rules="nameRules" class="w-100" placeholder="Name" solo
            dense></v-text-field>
        </div>
        <div class="col-md-12">
          <v-text-field v-model="loginForm.email" :rules="emailRules" class="w-100" placeholder="Email" solo
            dense></v-text-field>
        </div>
        <div class="col-md-12">
          <v-text-field v-model="loginForm.phone" type="number" :rules="phoneRules" class="w-100"
            placeholder="Contact No." solo dense></v-text-field>
        </div>
      </v-form>
      <div class="col-md-12 d-flex flex-column justify-content-start">
        <div class="pb-2 text-center text-danger">{{ error }}</div>
        <div class="pb-2 text-center text-success">{{ message }}</div>

        <v-btn @click="onSubmit()" class="m-auto rounded-10" elevation="5">
          Submit
        </v-btn>
      </div>
    </div>

    <div class="col-md-12 mt-2" v-if="isSubmited">
      <h1 class="mb-4">Thank You</h1>

      <p>
        We have received your request. We will get in touch with you shortly.
      </p>
      <p>
        In the meantime, you can explore the Fluke Experience and learn more
        about products and download related resources.
      </p>
    </div>
  </div>
</template>


<script >
import Vue from "vue";
import { baseURL } from "../helper/config";
import { contactUs } from "../helper/controller";
import { sendProductEvent } from "../helper/gtmTags";

export default Vue.extend({
  name: "Callback ",
  props: ["product"],
  data: () => {
    return {
      isSubmited: false,
      loginForm: {
        email: "",
        emailError: "",
        name: "",
        nameError: "",
        phone: "",
        phoneError: "",
      },
      baseURL: baseURL,
      error: "",
      message: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 80 || "Name must be less than 80 characters",
        (v) => /^[A-Za-z ]+$/.test(v) || "Name must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Mobile no is required",
        (v) => v.length <= 14 || "Name must be less than 14 characters",
        (v) => /^[0-9-+()]*$/.test(v) || "Mobile no must be valid",
      ],
      isFormValid: false,
      urlParams:{}
    };
  },
  methods: {
    onResetLink() {
      this.seed = Math.round(Math.random() * 100);
      this.isResetCode = !this.isResetCode;
      this.resetForm();
    },
    onSubmit() {
      debugger
      if (this.isFormValid) {
        let userData = {
          name: this.loginForm.name,
          email: this.loginForm.email,
          phone: this.loginForm.phone,
          product_id: this.product.id,
          utm_content: this.urlParams.get('utm_content'),
          utm_term: this.urlParams.get('utm_term'),
          utm_campaign: this.urlParams.get('utm_campaign'),
          utm_medium: this.urlParams.get('utm_medium'),
          utm_source: this.urlParams.get('utm_source')
        };
        this.submitCallbackForm(userData);
      }
    },

    submitCallbackForm(user) {
      contactUs(user)
        .then((res) => {
          if (res && res.data && res.isSuccess) {
            this.isSubmited = true;
            sendProductEvent("Contact us", "Request a Callback", "Email send");
          } else if (res && res.error) {
            this.error = res.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }
        })
        .catch((error) => {
          this.seed = Math.round(Math.random() * 100);
          if (error.response && error.response.data) {
            // Request made and server responded
            this.error = error.response.data.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }
          console.log(error);
        });
    },

    resetForm() {
      this.loginForm = {
        email: "",
        // emailError: "",
        code: "",
        // codeError: "",
        captcha: "",
        // captchaError: "",
      };
      this.$refs.form.resetValidation();

      this.error = "";
      this.message = "";
    },
    onBack() {
      this.seed = Math.round(Math.random() * 100);
      this.$emit("onBack");
    },
  },
  mounted() {
    // window.history.pushState(null, null, "/login");
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  },
  destroyed() {
    this.resetForm();
    this.isSubmited = false;
  },
});
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Gothic A1";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;

  color: #000000;
}

.reset-link {
  font-family: "Gothic A1";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-top: 20px !important;
}
</style>
