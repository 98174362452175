












import Vue from "vue";
import { imageBaseURL } from "../helper/config";
// import { saveAs } from "file-saver";  
var FileSaver = require('file-saver');


export default Vue.extend({
  name: "Literature",
  props: {
    literature: [],
  },
  methods: {
    getFileIcon(type: string) {
      let image;
      switch (type) {
        case "app-note":
          image = require("../assets/images/app_note.png");
          break;
        case "detailed-specification":
          image = require("../assets/images/app_note.png");
          break;
        case "datasheet":
          image = require("../assets/images/datasheet.png");
          break;
        case "customer-testimonial":
          image = require("../assets/images/testimonial.png");
          break;
        case "case-study":
          image = require("../assets/images/case_study.png");
          break;
        case "guide":
          image = require("../assets/images/guide.png");
          break;
        default:
          image = require("../assets/images/app_note.png");
          break;
      }
      return image;
    },
    onItem(v: any) {
      // window.open(imageBaseURL+ v.file,'_blank');
      this.$emit("onSelect", v);
    },
    onDownload(v: any) {
      FileSaver.saveAs(imageBaseURL + v.file, v.name);
      this.$emit("onDownload", v);
    },
  },
});
