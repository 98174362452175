<template>
	<div class="d-sm-none bg-white blocker d-flex flex-column align-items-center justify-content-center text-center p-3">
		<div>
			<img class="logo-image" src="../assets/images/fluke.png" />
		</div>
		<div class="fw-bold">For the optimal experience, we suggest using a desktop or laptop device. If you're accessing the application on a mobile phone, please rotate your device to view it.</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.blocker {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999999999 !important;
}

.logo-image {
	width: 100%;
	max-width: 300px;
	margin-bottom: 20px;
}
</style>
