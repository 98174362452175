







import Vue from "vue";

export default Vue.extend({
  name: "Information",
  props: ["product"],
});
