import axios from "axios";
import { httpRoutesURL, baseURL } from "./config";

export const getProductByGtm = (gtmId: string) => {
  const url = `${httpRoutesURL}/products?filter[gtm][_eq]=${gtmId}&fields=id,name,redirect_link,description,url,image,slider_image.*,gtm.*,items.*`;
  return axios.get(url).then((res) => {
    return res && res.data && res.data.data;
  });
};

export const getProductByID = (productId: string) => {
  const url = `${httpRoutesURL}/products?filter[id][_eq]=${productId}&fields=id,gtm.*`;
  return axios.get(url).then((res) => {
    return res && res.data && res.data.data[0];
  });
};

export const getVideos = (videoId: string) => {
  const url = `${httpRoutesURL}/Tv_Videos?filter[gtm][_eq]=${videoId}&fields=id,gtm.*,items.*.*`;
  return axios.get(url).then((res) => {
    return res && res.data && res.data.data[0];
  });
};

export const register = (user: any) => {
  const url = `${baseURL}/users`;
  return axios.post(url, user).then((res) => {
    return res && res.data;
  });
};

export const login = (user: any) => {
  const url = `${baseURL}/users/login`;
  return axios.post(url, user).then((res) => {
    return res && res.data;
  });
};

export const resetCode = (user: any) => {
  const url = `${baseURL}/users/forgotPassword`;
  return axios.post(url, user).then((res) => {
    return res && res.data;
  });
};

export const contactUs = (user: any) => {
  const url = `${baseURL}/users/contactUs`;
  return axios.post(url, user).then((res) => {
    return res && res.data;
  });
};