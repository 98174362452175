import store from "../store";

export const sendSkinEvent = (action) => {
  // window.dataLayer.push({
  //   'event': 'dl_gaevent_bnc',
  //   'bnc_eventCategory': "Skin",
  //   'bnc_eventAction': action,
  //   'bnc_userId': store.state.user ? store.state.user.id : undefined
  // });
};

export const sendProductEvent = (category, action, label = undefined) => {
  window.dataLayer.push({
    'event': 'dl_gaevent_bnc',
    'bnc_eventCategory': category,
    'bnc_eventAction': action,
    'bnc_eventLabel': label,
    'bnc_userId': store.state.user ? store.state.user.id : undefined
  });
};

export const sendProductItemEvent = (category, action, label = undefined, item, assetName) => {
  window.dataLayer.push({
    'event': 'dl_gaevent_bnc',
    'bnc_eventCategory': category,
    'bnc_eventAction': action,
    'bnc_eventLabel': label,
    'action': item,
    'asset_name': assetName,
    'bnc_userId': store.state.user ? store.state.user.id : undefined
  });
};

