<template>
  <div class="product-text">
    <div
      v-if="formStep == 1 || formStep == 0"
      class="w-100 d-flex justify-content-end pr-3"
    >
      <v-btn @click="onBack()" class="rounded-10" elevation="5">Back</v-btn>
    </div>

    <form>
      <div class="col-md-12" v-if="formStep == 0">
        <h1 class="mb-4 ml-3">Tell us about yourself</h1>
        <v-form v-model="form1Valid">
          <div class="col-md-12">
            <v-text-field
              v-model="formField.name"
              :rules="nameRules"
              class="w-100"
              placeholder="Full Name"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12">
            <v-text-field
              v-model="formField.email"
              :rules="emailRules"
              class="w-100"
              placeholder="Email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <!-- <v-text-field
              v-model="formField.mobile_no"
              :rules="phoneRules"
              class="w-100"
              placeholder="Mobile no."
              solo
            ></v-text-field> -->
            <vue-tel-input
              v-model="formField.mobile_no"
              class="w-100"
              placeholder="Mobile no."
              mode="international"
              defaultCountry="IN"
              invalidMsg="Mobile no must be valid"
              @blur="onPhoneBlur"
              @input="phoneValidateChange"
            ></vue-tel-input>
            <div class="v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">{{ phoneErr }}</div>
                </div>
              </div>
            </div>
          </div>
        </v-form>
        <div class="col-md-12 d-flex flex-column justify-content-center">
          <div class="pb-2 text-center text-danger">{{ error }}</div>
          <v-btn @click="onNext()" class="m-auto rounded-10" elevation="5"
            >Proceed to Next >></v-btn
          >
        </div>
      </div>

      <div class="col-md-12" v-if="formStep == 1">
        <h1 class="mb-4 ml-3">You are almost there</h1>
        <v-form ref="form2" v-model="form2Valid">
          <div class="col-md-12">
            <v-text-field
              v-model="formField.company"
              :rules="companyRules"
              class="w-100"
              placeholder="Company"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12">
            <v-text-field
              v-model="formField.designation"
              :rules="designationRules"
              class="w-100"
              placeholder="Designation"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 mb-4">
            <div class="form-check">
              <input
                class="form-check-input"
                name="userTNC"
                id="flexCheckDefault"
                type="checkbox"
                v-model="formField.userTNC"
              />
              <label class="form-check-label ps-2 ml-3" for="flexCheckDefault">
                "By submitting Fluke webform, I agree to receive promotional
                calls on the number shared, and such calls and SMS would be
                coming from a third party platform."
              </label>
              <div class="form-validation mt-2" v-if="formField.userTNCError">
                Please accept terms & conditions.
              </div>
            </div>
          </div>
        </v-form>
        <div class="col-md-12 d-flex flex-column justify-content-center">
          <div class="pb-2 text-center text-danger">{{ error }}</div>
          <div class="pb-2 text-center text-success">{{ message }}</div>
          <v-btn @click="onSubmit()" class="m-auto rounded-10" elevation="5"
            >Submit</v-btn
          >
        </div>
      </div>

      <div class="col-md-12" v-if="formStep == 2">
        <div class="m-auto signup-success w-75">
          <p class="mb-4 mt-5 ml-3">
            You have successfully registered. Please check your inbox for the
            login code. Please also check your spam or junk folder.
          </p>
        </div>

        <div class="col-md-12 mt-5 d-flex justify-content-center">
          <v-btn @click="onLogin()" class="m-auto rounded-10" elevation="5"
            >Go to Login</v-btn
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import { register } from "../helper/controller";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default Vue.extend({
  name: "Register",
  props: {},
  data: () => {
    return {
      phoneErr: "",
      formField: {
        name: "",
        nameError: "",
        email: "",
        mobile_no: "",
        company: "",
        designation: "",
        userTNC: "",
        userTNCError: "",
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 80 || "Name must be less than 80 characters",
        (v) => /^[A-Za-z ]+$/.test(v) || "Name must be valid",
      ],
      companyRules: [
        (v) => !!v || "Company is required",
        (v) => v.length <= 80 || "Company must be less than 80 characters",
        (v) => /^[A-Za-z ]+$/.test(v) || "Company must be valid",
      ],
      designationRules: [
        (v) => !!v || "Designation is required",
        (v) => v.length <= 80 || "Designation must be less than 80 characters",
        (v) => /^[A-Za-z ]+$/.test(v) || "Designation must be valid",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Mobile no is required",
        (v) => v.length <= 14 || "Name must be less than 14 characters",
        (v) => /^[0-9-+()]*$/.test(v) || "Mobile no must be valid",
      ],
      error: "",
      formStep: 0,
      form1Valid: false,
      form2Valid: false,
    };
  },
  components: {
    VueTelInput,
  },
  methods: {
    onNext() {
      debugger;
      if (this.form1Valid && this.formField.mobile_no && !this.phoneErr) {
        this.formStep = 1;
        setTimeout(() => {
          this.$refs.form2.resetValidation();
        }, 50);
      }
    },

    onSubmit() {
      this.formField.userTNCError = this.formField.userTNC
        ? ""
        : "Please accept terms & conditions.";

      if (!this.formField.userTNCError && this.form2Valid) {
        this.postRegisterData();
      }
    },

    onPhoneBlur() {
      this.formField.mobile_no = this.formField.mobile_no.split(" ").join("");
      if (!this.formField.mobile_no) {
        this.phoneErr = "Mobile no is required";
      } else if (this.formField.mobile_no.length >= 14) {
        this.phoneErr = "Name must be less than 14 characters";
      }
    },

    phoneValidateChange(text, args) {
      if (text) {
        if (args.valid) {
          this.phoneErr = "";
        } else {
          this.phoneErr = "Mobile no must be valid";
        }
      }
    },

    postRegisterData() {
      this.error = "";
      register(this.formField)
        .then((res) => {
          if (res && res.isSuccess) {
            window.dataLayer.push({
              event: "dl_gaevent_bnc",
              bnc_eventCategory: "Registration",
              bnc_eventAction: "Registration Success",
              bnc_eventLabel: undefined,
              bnc_userId: res.data.id,
            });
            this.formStep = 2;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data) {
            if (error.response.data.error == "Error: User already exist") {
              this.error =
                "You have already registered with us in the past. Kindly check your email inbox/spam folder for the login code received from account@experiencefluke.com";
              return;
            }
            this.error = error.response.data.error;
          } else {
            this.error = "Some Error Occurred. Try Again.";
          }
        });
    },
    onLogin() {
      this.error = "";
      this.$emit("onLogin");
    },

    onBack() {
      this.error = "";
      if (this.formStep == 1) {
        this.formStep = 0;
      } else {
        this.$emit("onBack");
      }
    },
  },
  mounted() {
    // window.history.pushState(null, null, "/register");
  },
  destroyed() {
    // window.history.pushState(null, null, "/");
  },
});
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Gothic A1";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;

  color: #000000;
}
.back {
  padding: 2px 5px;
  font-weight: bold;
  font-size: 16px;
  float: right;
}
.signup-success {
  margin-top: 20% !important;
  p {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    text-align: left;
    color: #000000;
  }
}

.vue-tel-input {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: #ffffff;
  font-family: "Gothic A1";
  font-size: 0.925rem;
  padding: 0.508rem 0rem !important;
  color: #000000;

  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 10px !important;
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  border-width: 0px;
}

.vue-tel-input ::placeholder {
  color: #9e9e9e;
}
.v-text-field__details {
  padding: 0 12px;
}
@media (max-width: 767.98px) {
  .signup-success {
    width: 100% !important;
    margin-top: 40% !important;
  }
}
</style>
