import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import moment from "moment";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "demo",
    user: null,
    isMuted: localStorage.getItem("isMuted") == "true" ? true : false,
  },
  mutations: {
    setUserToken(state, val) {
      if (state.token) return;
      state.token = val;
    },
    setIsMuted(state, val) {
      state.isMuted = val;
    },
    setUser(state, val) {
      state.user = val;
      const ls = new SecureLS({ encodingType: "aes" });
      ls.set("session", val);
      ls.set("session_time", moment().format());
    },
  },
  actions: {
    setChatName() {
      if (!this.state.user) {
        return;
      }
      if (this.state.user) {
        (window as any).Tawk_API.visitor = {
          name: (this.state.user as any).name,
          email: (this.state.user as any).email,
          phone: (this.state.user as any).mobile_no,
        };
      }
    },

    setUserDataFromLocal({ commit }) {
      const ls = new SecureLS({ encodingType: "aes" });
      const savedTime = ls.get("session_time");
      // var savedTime = "2022-02-13T21:59:08+05:30";

      const duration = moment.duration(moment().diff(savedTime));
      const hours = duration.asHours();
      if (hours < 4) {
        console.log("session started.");
        const user = ls.get("session");
        if (user) {
          commit("setUserToken", user.token);
          commit("setUser", user);
        }
      } else {
        console.log("session ended.");
      }
    },
  },
  modules: {},
});
